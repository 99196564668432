import heroImage from '../assets/images/hero-img.png';
import features1 from '../assets/images/features-1.png';
import features2 from '../assets/images/features-2.png';
// import features3 from '../assets/images/features-3.png';
// import features4 from '../assets/images/features-4.png';
// import { configDotenv } from 'dotenv';
// require('dotenv').config()

export function Header() {
  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="https://maejo.link">MAEJO LINK </a><span style={{ fontSize: "0.6em", fontWeight: "300" }}>{process.env.REACT_APP_VERSION}</span>
          </h1>

          {/* <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a
                  className="getstarted scrollto"
                  href="#a"
                  data-bs-toggle="modal"
                  data-bs-target="#SignForm"
                >
                  Get Started
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav> */}
        </div>
      </header>
    </>
  );
}

export function Footer() {
  return (
    <footer id="footer">
      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Maejo University</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="https://m.me/umnarj" target="_blank" rel="noreferrer">
              @Umnarj
            </a>{" "}
            |{" "}
            <a href="https://m.me/nuttaohouy" target="_blank" rel="noreferrer">
              @Nutchapong
            </a>
          </div>
        </div>
        {/* <div className="social-links text-center text-md-right pt-3 pt-md-0"></div> */}
      </div>
    </footer>
  );
}

export function HeroTitle(params) {
  const loginUrl = params.loginUrl;
  return (
    <section id="hero" className="d-flex align-items-center">
      <div
        className="container d-flex flex-column align-items-center justify-content-center"
        data-aos="fade-up"
      >
        <h1 style={{ letterSpacing: "2px" }}>
          ย่อลิงก์ให้สั้น &<br />
          สร้าง QR Code อัตโนมัติ
        </h1>
        <h2>
          ระบบฐานข้อมูลการจัดการย่อลิงก์ และสร้างคิวอาร์โค๊ด<br />สำหรับบุคลากร มหาวิทยาลัยแม่โจ้
        </h2>
        <a
          href={loginUrl}
          className="btn-get-started scrollto"
        >
          เข้าสู่ระบบ
        </a>
        <img
          src={heroImage}
          className="img-fluid hero-img"
          alt=""
          data-aos="zoom-in"
          data-aos-delay="150"
        />
      </div>
    </section>
  );
}

export function Contents() {
  return (
    <main id="main">
      <section id="features" className="features" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>FEATURES</h2>
          </div>

          <div className="row content">
            <div className="col-md-5" data-aos="fade-right" data-aos-delay="100">
              <img src={features1} className="img-fluid" alt="" />
            </div>
            <div
              className="col-md-7 pt-4"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h3>ความสามารถของระบบ</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> ระบบสามารถย่อลิงก์ยาวๆ
                  ให้สั้นลงได้มาก
                </li>
                <li>
                  <i className="bi bi-check"></i> สามารถสร้าง QR Code
                  จากลิงก์ที่ย่อแล้วได้ทันที
                </li>
                <li>
                  <i className="bi bi-check"></i> นั่นทำให้การสร้าง QR Code
                  จะสแกนง่ายขึ้น
                </li>
                <li>
                  <i className="bi bi-check"></i> หากลิงก์เปลี่ยนแปลง
                  สามารถกลับมาแก้ไข โดยไม่ต้องเสียเวลาสร้าง QR Code ใหม่เรื่อยๆ
                </li>
                <li>
                  <i className="bi bi-check"></i>{" "}
                  สามารถลบลิงก์ที่ไม่ต้องการใช้แล้วได้อีกด้วย
                </li>
                <li>
                  <i className="bi bi-check"></i> เพื่อความปลอดภัย ผู้ใช้งานแต่ละคน
                  จะเห็นข้อมูลที่ตนเองสร้างไว้เท่านั้น
                </li>
                <li>
                  <i className="bi bi-check"></i> บุคลากรในมหาวิทยาลัยแม่โจ้ สามารถ
                  Sign-In เข้าใช้ได้ทันที
                </li>
              </ul>
            </div>
          </div>

          <div className="row content">
            <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
              <img src={features2} className="img-fluid" alt="" />
            </div>
            <div className="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
              <h3>ขั้นตอนการใช้งาน</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> คลิก Sign-In
                  เพื่อลงชื่อเข้าสู่ระบบด้วยรหัสผ่านของ ERP.mju.ac.th
                </li>
                <li>
                  <i className="bi bi-check"></i> สร้าง ระบุลิงก์ปลายทาง แล้วกดปุ่ม
                  สร้างลิงก์
                </li>
                <li>
                  <i className="bi bi-check"></i> แก้ไข ให้กดปุ่ม แก้ไข
                  แล้วระบุลิงก์ปลายทางใหม่ แล้วกดปุ่ม บันทึก
                </li>
                <li>
                  <i className="bi bi-check"></i> ลบ ให้กดปุ่ม แก้ไข แล้วกดปุ่ม ลบ
                </li>
                <li>
                  <i className="bi bi-check"></i> กดปุ่ม QR เพื่อสร้างลิงค์ในรูปแบบ
                  QR Code
                </li>
                <li>
                  <i className="bi bi-check"></i> ออกจากระบบ กดปุ่ม Sign-Out
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
